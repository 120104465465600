import { ChangeEvent, MouseEvent, useState } from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { useSnackbar } from "notistack";
import { setAssignTeam } from "api";
import { setAssignTeamPayload } from "api/types_TEMP";
import { ReusableSelect } from "utils/reusableSelect";
import configuration from "utils/configuration";
import useUser from "utils/hooks/useUser";
import DeededPopup from "v2/components/DeededPopup";
import { memberStyles } from "./dealDetailsStyles";
import useGetProfessionalsForList from "../../../utils/hooks/useGetProfessionalsForList";

const assignTeamList = [
    { value: "Realtor", label: "Realtor" },
    { value: "Real Estate/Mortgage Team", label: "Real Estate/Mortgage Team", },
    { value: "Broker", label: "Broker" },
    { value: "Lender", label: "Lender" },
    { value: "Referring Professional", label: "Referring Professional" },
];

interface AssignMemberPopupProps {
    assignTeam: boolean,
    handleAssignMember: () => {},
    setOpenModal: () => void,
    dealId: number
}

const AssignMemberPopup = ({
    assignTeam,
    handleAssignMember,
    setOpenModal,
    dealId
}: AssignMemberPopupProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useUser();
    const [professionalList] = useGetProfessionalsForList();
    
    const [errors, setErrors] = useState<Record<string, boolean>>({});
    const [memberData, setMemberData] = useState<Record<string, any>>({
        first_name: "",
        middle_name: "",
        last_name: "",
        email: "",
        phone: "",
        role: { label: "", value: "" },
    });
    const [selectedProfessional, setSelectedProfessional] = useState<null | number>(null);

    const handleSubmit = (e: MouseEvent) => {
        e.preventDefault();
        const errorMap: Record<string, boolean> = {};
        for (let key in memberData) {
            if (key === "middle_name") continue;
            if (memberData[key]?.value) {
                errorMap[key] = !memberData[key]?.value;
                continue;
            }
            errorMap[key] = !memberData[key];
        }
        setErrors(errorMap);
        if (Object.values(errorMap).some((error) => !!error)) {
            return enqueueSnackbar("Please, fill required fields", {
                variant: "error", autoHideDuration: configuration.autoHideErrorDuration
            });
        }

        const payload = { ...memberData, role: memberData?.role?.value } as setAssignTeamPayload;
        setAssignTeam(dealId, payload).then(() => {
            enqueueSnackbar("Assigning team member", {
                variant: "success",
                autoHideDuration: 1500,
            });
            setMemberData({
                first_name: "",
                middle_name: "",
                last_name: "",
                email: "",
                phone: "",
                role: { label: "", value: "" },
            });
            handleAssignMember();
        }).catch((error) => {
            enqueueSnackbar(error?.response?.data?.error ?? "There was an error assigning team member", {
                variant: "error",
                autoHideDuration: configuration.autoHideErrorDuration,
            });
        })
    }

    const handleProfessionalSelect = (e: Record<string, string>) => {
        setSelectedProfessional(Number(e.id));
        setMemberData({
            ...memberData,
            first_name: e.first_name,
            middle_name: e.middle_name,
            last_name: e.last_name,
            email: e.email,
            phone: e.phone,
        })

    }
    const handleAssignMemberInputs = (e: ChangeEvent) => {
        const { name, value } = e.target as HTMLInputElement;
        setMemberData({ ...memberData, [name]: value });
        setErrors({});
    };
    const handleNumberInput = (event: MouseEvent) => {
        const { value } = event.target as HTMLInputElement;
        const regExp = /\d{3}-\d{3}-\d{4}/;
        setErrors({ ...errors, phone: !value.match(regExp) });
    }

    return <DeededPopup
        open={assignTeam}
        setOpen={setOpenModal}
        title="Assign team"
        sxInternalBox={{
            width: "325px",
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
                width: "5px",
                scrollbarWidth: "thin",
            },
        }}
    >
        <div className="assign-wrapper">
            <form className="assign__team">
                <div className="assign__team--content">
                    <div className="left">
                        <label htmlFor="member">Choose a member</label>

                        <Select
                            id="deal-details-assign-team-choose-member-drop-down"
                            className={errors?.role ? "input-error" : ""}
                            styles={{ ...memberStyles, }}
                            options={assignTeamList}
                            value={memberData.role}
                            onChange={(e) => setMemberData({
                                ...memberData,
                                role: { label: e.value, value: e.value }
                            })}
                        />

                        {(user.role === "Admin" || user.role === "System Admin")
                            && memberData.role.value === "Referring Professional"
                            && (
                                <div className="assign__card">
                                    <div className="status">
                                        <label
                                            htmlFor="professinal_list"
                                            style={{
                                                width: "25.5rem",
                                                visibility: "visible",
                                            }}
                                        >
                                            <div data-testid="assign-team-professional-drop-down">
                                                <ReusableSelect
                                                    defaultStyles={false}
                                                    defaultValue={!selectedProfessional ?
                                                        { label: "", value: "" }
                                                        : professionalList?.find((prof) => prof.id === selectedProfessional)
                                                    }
                                                    options={professionalList || []}
                                                    onChangeHandler={handleProfessionalSelect}
                                                />
                                            </div>
                                        </label>
                                    </div>
                                </div>)}
                        {errors?.role && (
                            <span className="input-error-text">
                                Field is required
                            </span>
                        )}
                        {memberData.role.value !== "Referring Professional" &&
                            (<>
                                <label htmlFor="first_name">First Name</label>
                                <input
                                    data-testid="deal-details-assign-team-first-name-input"
                                    required
                                    className={
                                        errors?.first_name
                                            ? "input-error"
                                            : ""
                                    }
                                    type="text"
                                    name="first_name"
                                    autoComplete="new-password"
                                    value={memberData.first_name}
                                    onChange={handleAssignMemberInputs}
                                />
                                {errors?.first_name && (
                                    <span className="input-error-text">
                                        Field is required
                                    </span>
                                )}
                                <label htmlFor="last_name">Middle Name</label>
                                <input
                                    data-testid="deal-details-assign-team-middle-name-input"
                                    required
                                    className={
                                        errors?.middle_name
                                            ? "input-error"
                                            : ""
                                    }
                                    autoComplete="new-password"
                                    type="text"
                                    name="middle_name"
                                    value={memberData.middle_name}
                                    onChange={handleAssignMemberInputs}
                                />
                                {errors?.middle_name && (
                                    <span className="input-error-text">
                                        Field is required
                                    </span>
                                )}
                                <label htmlFor="last_name">Last Name</label>
                                <input
                                    data-testid="deal-details-assign-team-last-name-input"
                                    required
                                    className={
                                        errors?.last_name ? "input-error" : ""
                                    }
                                    autoComplete="new-password"
                                    type="text"
                                    name="last_name"
                                    value={memberData.last_name}
                                    onChange={handleAssignMemberInputs}
                                />
                                {errors?.last_name && (
                                    <span className="input-error-text">
                                        Field is required
                                    </span>
                                )}
                                <label htmlFor="primaryEmail">Primary Email</label>
                                <input
                                    data-testid="deal-details-assign-team-email-input"
                                    autoComplete="new-password"
                                    required
                                    className={
                                        errors?.email ? "input-error" : ""
                                    }
                                    type="text"
                                    name="email"
                                    value={memberData.email}
                                    onChange={handleAssignMemberInputs}
                                />
                                {errors?.email && (
                                    <span className="input-error-text">
                                        Field is required
                                    </span>
                                )}
                                <label htmlFor="mobilePhone">
                                    Mobile Phone Number
                                </label>
                                <NumberFormat
                                    data-testid="deal-details-assign-team-mobile-phone-input"
                                    className={errors?.phone ? "input-error" : ""}
                                    autoComplete="new-password"
                                    style={{ border: errors?.phone ? "1px solid red" : "" }}
                                    required
                                    value={memberData.phone}
                                    name="phone"
                                    format="###-###-####"
                                    onChange={(e) => {
                                        handleAssignMemberInputs(e);
                                        handleNumberInput(e);
                                    }}
                                />
                                {errors?.phone && (
                                    <span className="input-error-text">
                                        Field is required
                                    </span>
                                )}
                            </>
                            )}
                        <button
                            data-testid="deal-details-assign-team-submit-button"
                            onClick={handleSubmit}
                            className="submit__btn"
                        >
                            submit
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </DeededPopup>;
}

export default AssignMemberPopup;