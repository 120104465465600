import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Stack,
  styled,
  TableCell,
  TableRow,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {Deal} from "types/deal";
import {User, UserParticipant, UserRoleType} from "types/user";
import {convertClosingDateToDisplayRepresentation} from "utils/date";
import constants from "styles/constants";
import {
  archiveDealTC,
  deleteDealTC,
} from "redux/reducers/dealsReducer";
import {truncateString} from "utils/truncateString";
import DeleteIcon from "components/Svg/DeleteIcon";
import DealListItemMobile from "../DealListItemMobile";
import DeededHintCloud from "v2/components/DeededHintCloud";
import DeededPopup from "v2/components/DeededPopup";
import DeededButton from "v2/components/DeededButton";
import { sendDashDealToConveyance } from "api";
import { addErrorAC } from "redux/reducers/ErrorReducer";
import BasicTooltip from "components/Common/Tooltip/Tooltip";
import { CalendarIcon } from "components/Common/Icons/Iconography";
import Colors from "components/Common/componentStyling/Colors";
import { useConveyance } from "utils/hooks/useConveyance";
import Checkbox from "components/Common/Checkbox";

interface DealsListItemProps {
  user: Partial<User> | undefined;
  deal: Deal;
  id: number;
  deletePopup: (id: number) => void;
  isLead?: boolean;
  getCurrentList: () => void;
  visibleColumns?: Array<string> | undefined;
}

const DealsListItemTableBody = ({
  isLead,
  user,
  deal,
  id,
  deletePopup,
  getCurrentList,
  visibleColumns = []
}: DealsListItemProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const theme = useTheme();
  const { conveyanceEnabled, dealConveyable } = useConveyance({ deal: deal, user: user });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const {full_name, first_name, name_prefix} = deal.contacts_with_trashed?.find(
    (user: UserParticipant) => user?.role === "Client",
  ) ?? {
    full_name: "",
    first_name: "",
    name_prefix: "",
  };
  const [provinceColor, setProvinceColor] = useState("");
  const [dealTypeColor, setDealTypeColor] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    switch (deal?.address?.state) {
      case "AB":
        setProvinceColor(constants.provinceColors.AB);
        break;
      case "BC":
        setProvinceColor(constants.provinceColors.BC);
        break;
      case "ON":
        setProvinceColor(constants.provinceColors.ON);
        break;
      case "NB":
        setProvinceColor(constants.provinceColors.NB);
        break;
      case "NC":
        setProvinceColor(constants.provinceColors.NC);
        break;
      case "NL":
        setProvinceColor(constants.provinceColors.NL);
        break;
      case "NT":
        setProvinceColor(constants.provinceColors.NT);
        break;
      default:
        setProvinceColor(constants.provinceColors.Other);
    }

    switch (deal.deal_type) {
      case "Purchase":
        setDealTypeColor(constants.dealTypeColor.Purchase);
        break;
      case "Sale":
        setDealTypeColor(constants.dealTypeColor.Sale);
        break;
      case "Lender":
        setDealTypeColor(constants.dealTypeColor.Lender);
        break;
      default:
        setDealTypeColor(constants.dealTypeColor.Mortgage);
    }
  }, [deal?.address?.state, deal.deal_type]);

  const logicForCompletedStage = () => {
    const {completed_stages, available_stages, deal_stage} = deal;
    const isNotMortgageOrIsEmpty =
      completed_stages.length <= 1 ||
      completed_stages?.[completed_stages.length - 1]?.stage_reached !==
        "Mortgage";
    const isMortgage =
      completed_stages?.[completed_stages.length - 1]?.stage_reached ===
      "Mortgage";

    if (isNotMortgageOrIsEmpty) {
      return deal_stage !== null ? deal_stage.name : "";
    } else if (isMortgage) {
      const completedPreviousStageName =
        completed_stages?.[completed_stages.length - 2]?.stage_reached;
      const completedPreviousStageId =
        completed_stages?.[completed_stages.length - 2].deal_stage_id;
      const mortgageStageId =
        completed_stages?.[completed_stages.length - 1].deal_stage_id;
      const preMortgageStage = available_stages.filter((el: {id: number}) => {
        return el.id === mortgageStageId - 1;
      });

      if (preMortgageStage[0].id === completedPreviousStageId) {
        return deal_stage !== null ? deal_stage.name : "";
      } else {
        return completedPreviousStageName;
      }
    }
  };

  const getStaffInitials = (type:UserRoleType) => {
    let staff = deal.staff?.filter(s => s.role === type)

    return !staff.length ? '-' : staff.map(s => {
      return (<span key={`staff-${s.full_name}`} title={s.full_name}>{s.first_name[0] + s.last_name[0]}</span>)
    })
  }

  const getAppointmentInformation = () => {
    if (deal?.appointment && deal.appointment.length > 0) {
      const apptCount = deal.appointment.length;
      const appts = deal.appointment.sort((appt1, appt2) => new Date(appt1.datetime).getTime() - new Date(appt2.datetime).getTime());
      return {
        apptCount,
        label: convertClosingDateToDisplayRepresentation(deal.appointment[0].datetime),
        tooltip: apptCount > 1 ? appts.map((appt) => convertClosingDateToDisplayRepresentation(appt.datetime)).join("\n") : null
      }
    }
    return { apptCount: 0, label: '-', tooltip: null };
  }

  const handleDeleteDeal = () => {
    if (isLead || deal.is_archive) {
      dispatch(deleteDealTC(deal.id, getCurrentList));
    } else {
      dispatch(archiveDealTC(deal.id, isTest, getCurrentList));
    }
    setIsModalOpen(false);
  };

  const createdAtDisplayString = deal.created_at?.split(" ")?.[0];
  const closingDateDisplayString = convertClosingDateToDisplayRepresentation(
    deal.closing_date,
  ) || '-';

  const handleClickOnDeal = () => {
    history.push(`/dashboard/${deal.status ? "deals" : "leads"}/${deal.id}`);
  };

  const handleClickDelete = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();
    deletePopup(id);
    setIsModalOpen(true);
  };

  const conveyDeal = async (dealId: number, e: any) => {
    e.stopPropagation();
    await sendDashDealToConveyance(dealId)
    .then(function (response: any) {
      history.push(`/dashboard/conveyance/conveyance-deals/${response.data.id}/data-sheet`);
    })
    .catch(function (e) {
      dispatch(addErrorAC(e.response.data.message ?? "Cannot convey this deal"));
    })
  };

  const columns = [
    {
      type: "type",
      content: (
        <TableBodyItemCell
          data-testid='my-deals-type-column'
          theme={theme}
        >
          {" "}
          <DealText
            theme={theme}
            sx={{
              color: dealTypeColor,
            }}
          >
            {deal.deal_type}
          </DealText>
          {deal.deal_type === "Mortgage" &&
            deal.deal_type_tag &&
            deal.deal_type_tag.tag !== "default" && (
              <Typography
                sx={{
                  height: "16px",
                  backgroundColor: constants.dealTypeColor.Mortgage,
                  color: constants.colors.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "2px",
                  fontSize: "12px",
                  fontWeight: "500",
                  width: "73px",
                  [theme.breakpoints.down(1440)]: {
                    fontSize: "10px",
                    width: "63px",
                  },
                  [theme.breakpoints.down(1355)]: {
                    fontSize: "8px",
                    width: "53px",
                  },
                }}
              >
                {deal.deal_type_tag.tag}
              </Typography>
            )}
        </TableBodyItemCell>
      )
    },
    {
      type: "province",
      content: (
        <TableBodyItemCell data-testid="my-deals-province-column" theme={theme}>
          <Box>
            <ProvinceBox sx={{background: provinceColor}}>
              {deal?.address?.state || "-"}
            </ProvinceBox>
          </Box>
        </TableBodyItemCell>
      )
    },
    {
      type: "client_name",
      content: (
        <TableBodyItemCell
          sx={{
            position: "relative",
          }}
          theme={theme}
        >
          <DeededHintCloud
            text={name_prefix === "Corporation" ? first_name : full_name}
            copied={false}
            bottom={50}
            left={0}
            marginLeftRotatedSquare={20}
            className="noActive"
          />
          <DealText data-testid="my-deals-client-column" theme={theme} sx={{maxWidth: "130px"}}>
            {full_name}
          </DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "street_address",
      content: (
        <TableBodyItemCell data-testid="my-deals-address-column" theme={theme}>
          <DealText theme={theme}>{deal?.address?.address.slice(0, 30)}</DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "appointment",
      content: (
        <TableBodyItemCell data-testid="my-deals-signing-appointment-column" theme={theme}>
          <Stack flexDirection={'row'} gap={'2rem'} justifyContent={'flex-start'} alignItems={'center'}>
            <DealText theme={theme}>{getAppointmentInformation().label}</DealText>
            {getAppointmentInformation().tooltip && <BasicTooltip
              message={getAppointmentInformation().tooltip || ''}
              placement={'top'}
            >
              <CalendarBox>
                <CalendarIcon color={Colors.GRAY_700}/>
              </CalendarBox>
            </BasicTooltip>}
          </Stack>
        </TableBodyItemCell>
      )
    },
    {
      type: "lawyer",
      content: (
        <TableBodyItemCell data-testid="my-deals-lawyer-column" theme={theme}>
          <DealText theme={theme}>{getStaffInitials('Lawyer' as UserRoleType)}</DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "law_clerk",
      content: (
        <TableBodyItemCell data-testid="my-deals-law-clerk-column" theme={theme}>
          <DealText theme={theme}>{getStaffInitials('Law Clerk' as UserRoleType)}</DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "created_at",
      content: (
        <TableBodyItemCell data-testid="my-deals-created-column" theme={theme}>
          <DealText theme={theme}>{createdAtDisplayString}</DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "closing",
      content: (
        <TableBodyItemCell data-testid="my-deals-closing-column" theme={theme}>
          <DealText theme={theme}>{closingDateDisplayString}</DealText>
        </TableBodyItemCell>
      )
    },
    {
      type: "deal_stage",
      content: (
        <TableBodyItemCell
          data-testid="my-deals-deal-stage-column"
          sx={{
            position: "relative",
          }}
          theme={theme}
        >
          <BasicTooltip message={logicForCompletedStage() || ""} placement='top'>
          <DealText theme={theme}>
            {truncateString(logicForCompletedStage() as string, 20)}
          </DealText>
          </BasicTooltip>
        </TableBodyItemCell>
      )
    },
    {
      type: "number",
      content: (
        <TableBodyItemCell data-testid="my-deals-number-column" theme={theme}>
            <DealText
              sx={{
                color: constants.colors.dealListItemNumber,
              }}
              theme={theme}
            >
              {deal.deal_number.slice(0, 9)}
            </DealText>
          </TableBodyItemCell>
      )
    },
    {
      type: "documents",
      content: (
        <TableBodyItemCell data-testid="my-deals-documents-column" theme={theme}>
          <DealText theme={theme}>
            {deal.document_status
              ? deal.document_status.replace(/&nbsp;/g, " ")
              : "0 / 0"}
          </DealText>
        </TableBodyItemCell>
      )
    },
  ]

  return (
    <>
      {isSmallScreen ? (<Box
        sx={{
          background: constants.colors.white,
          [theme.breakpoints.up("sm")]: {
            display: "none",
          },
        }}
      >
        <DealListItemMobile
          deal={deal}
          dealTypeColor={dealTypeColor}
          provinceColor={provinceColor}
          dealStage={logicForCompletedStage() as string}
          createdAtDisplayString={createdAtDisplayString}
          closingDateDisplayString={closingDateDisplayString}
          full_name={full_name}
          first_name={first_name}
          name_prefix={name_prefix}
          handleClickOnDeal={handleClickOnDeal}
          handleClickDelete={handleClickDelete}
        />
      </Box>) :
      (<ItemContainer theme={theme} onClick={handleClickOnDeal}>
        {...columns
          .sort((a, b) => visibleColumns.indexOf(a.type) - visibleColumns.indexOf(b.type))
          .filter(i => visibleColumns.includes(i.type))
          .map(i => i.content)
        }

        {/* Conveyance button */}
        {conveyanceEnabled ? (
          <TableBodyItemCell theme={theme}>
            {dealConveyable ? (
              <DeededButton
                sx={{
                  width: "75px !important",
                  height: "36px",
                }}
                kind="secondary"
                onClick={(e) => conveyDeal(deal.id, e)}
                className="cancel-appt"
                type="button"
                data-testid="my-deals-convey-button"
              >
                Convey
              </DeededButton>
            ) : null}
          </TableBodyItemCell>
        ) : null}
        <TableBodyItemCell theme={theme}>
          {(user?.role === "Admin" || user?.role === "System Admin") && (!deal.is_archive) && (
            <DeleteBox
              onClick={
                handleClickDelete as unknown as React.MouseEventHandler<HTMLDivElement>
              }
            >
              <DeleteIcon />
            </DeleteBox>
          )}
        </TableBodyItemCell>
      </ItemContainer>)}

      {isModalOpen && (
        <DeededPopup
          hideBtnClose={true}
          open={isModalOpen}
          setOpen={setIsModalOpen}
          sx={{"& .MuiDialog-paper": {borderRadius: "20px"}}}
          sxInternalBox={{
            width: "530px",
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
              scrollbarWidth: "thin",
            },
          }}
        >
          <DeleteContainer>
            <DeleteText>Delete this deal?</DeleteText>
          </DeleteContainer>

          {(!isLead && !deal.is_archive) && (
            <Stack mb={3} direction="row" justifyContent="center" width="100%">
              <Checkbox
                data-testid="close-deal-modal-cancellation-is-test-field"
                label="Test Deal"
                checked={isTest}
                onChange={() => setIsTest(!isTest)}
              />
            </Stack>
          )}
            
          <Box
            sx={{
              display: "flex",
              padding: "0 40px",
              [theme.breakpoints.down("sm")]: {padding: 0},
            }}
          >
            <YesButton
              sx={{...buttonStyles}}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteDeal();
              }}
            >
              Yes
            </YesButton>
            <NoButton
              sx={{...buttonStyles}}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
              }}
            >
              No
            </NoButton>
          </Box>
        </DeededPopup>
      )}
    </>
  );
};

const buttonStyles = {
  width: "175px",
  height: "50px",
  borderRadius: "5px",
  textTransform: "uppercase",
  fontSize: "16px",
  cursor: "pointer",
  fontWeight: "500",
  ":hover": {
    boxShadow: "0px 4px 4px rgb(79 79 79 / 25%",
  },
};

const YesButton = styled("button")({
  color: "white",
  border: "none",
  background: constants.colors.red,
  marginRight: "20px",
});
const NoButton = styled("button")({
  background: "transparent",
  border: "1px solid grey",
});

const DeleteText = styled(Typography)({
  fontSize: "24px",
  fontWeight: "600",
  marginBottom: "50px",
  color: constants.colors.intakeTitle,
});

const DeleteContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  background: "white",
});

const DeleteBox = styled(Box)({
  width: "24px",
  height: "24px",
  cursor: "pointer",
  svg: {
    stroke: constants.colors.deededGray,
    transition: constants.transitions,
    "&:hover": {
      stroke: constants.colors.red,
      transition: constants.transitions,
    },
  },
});

const TableBodyItemCell = styled(TableCell)<{theme: Theme}>(({theme}) => ({
  borderBottom: `4px solid ${constants.colors.bg}`,
  [theme.breakpoints.down(1440)]: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  [theme.breakpoints.down(1160)]: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
}));
const ProvinceBox = styled(Box)({
  width: "39px",
  height: "22px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "uppercase",
  fontWeight: "600",
  borderRadius: "4px",
  color: "white",
});

const ItemContainer = styled(TableRow)<{theme: Theme}>(({theme}) => ({
  cursor: "pointer",
  height: "50px",
  background: "white",
  marginBottom: "4px",
  padding: "0 16px",
  borderRadius: "2px",
  boxShadow: "0px 2px 4px rgba(161, 184, 214, 0.12)",
  transition: constants.transitions,
  "&:hover": {
    background: constants.colors.configNotificationsTableBorder,
    transition: constants.transitions,
  },
  [theme.breakpoints.down(1440)]: {
    maxHeight: "40px !important",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const DealText = styled(Typography)<{theme: Theme}>(({theme}) => ({
  fontSize: "14px",
  fontWeight: "400",
  fontFamily: "Montserrat",
  color: "#7F7F7F",
  textTransform: "capitalize",
  [theme.breakpoints.down(1440)]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down(1355)]: {
    fontSize: "10px",
  },
}));

const CalendarBox = styled(Box)({
  svg: {
    "&:hover": {
      path: {
        fill: constants.colors.red,
      },
    }
  },
});


export default DealsListItemTableBody;
